/* .main-app{
    position: relative;
} */

#whatsapp-icon{
    z-index: 9999999;
    position:fixed;
    bottom:40px;
    right:25px;
    display:flex;
    align-items: center;
    justify-content:center;
    height: 50px;;
    width:50px;
    color:#56FD5D;
    font-size:25px;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.05);
    background-color:white;
    border-radius:50%;
    cursor:pointer;
}

.left-first{
    left: 22px!important;
}

.left-second{
    left: 130px!important;
}

.left-third{
    left: 245px !important;
}
.left-fourth{
    left: 355px !important;
}
.left-fifth{
    left: 465px !important;
}
.left-sixth{
    left: 565px !important;
}

/* ----------------------------------------- *\
            #REUSED STYLE
\* ----------------------------------------- */

.yellow-badge{
    background-color: #FDF6B2;
    color:#D09113;
    padding: 5px 10px;
    border-radius: 5px;
}

.green-badge{
    background-color: #DEF7EC;
    color:#3C6B60;
    padding: 5px 10px;
    border-radius: 5px;
}

.red-badge{
    background-color: #FDE8E8;
    color:#9B1C2F;
    padding: 5px 10px;
    border-radius: 5px;
}

.display-none{
    display: none;
}

.primary-button{
    padding: 12px;
    min-width: 150px;
    background-color: #021d53;
    border-radius: 6px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .4s ease-in-out;
    border: none;
    font-size: 16px;
}

.primary-button:hover{
    border:1px solid #021d53;
    color: #021d53;
    background: transparent;
    cursor: pointer;
}

.primary-button-outline{
    padding: 12px;
    min-width: 150px;
    border:1px solid #021d53;
    border-radius: 6px;
    color: #021d53;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .4s ease-in-out;
}

.primary-button-outline:hover{
    background-color: #021d53;
    color: white;
    cursor: pointer;
}

.secondary-button{
    padding: 12px;
    min-width: 150px;
    background-color: #0442be;
    border-radius: 6px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.white-outline-btn{
    border:1px solid #ffffff;
    border-radius: 6px;
    color: #fff;
    background: transparent;
}

.white-outline-btn:hover{
    background-color: #fff;
    color: #021d53;
}

.red-outline-btn{
    border:1px solid #ff8d9e;
    border-radius: 6px;
    color: #ff8d9e;
    background: transparent;    
}

.red-outline-btn:hover{
    background-color: #ff8d9e;
    color: white;
}

.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .4s ease-in-out;
    padding: 12px;
    min-width: 150px;
}

.w-max-content{
    width: max-content;
}

/* LAPTOP VIEW 1024PX */
@media screen and (max-width: 1025px){
    .left-second{
        left:116px!important;
    }
    .left-third{
        left: 217px !important;
    }
    .left-fourth{
        left: 318px !important;
    }
    .left-fifth{
        left: 420px !important;
    }
    .left-sixth{
        left: 520px !important;
    }
}

/* TABLET VIEW 768PX */
@media screen and (max-width: 769px){
    .left-first{
        left: 5px!important;
    }
    .left-second{
        left:88px!important;
    }
    .left-third{
        left: 170px !important;
    }
    .left-fourth{
        left: 250px !important;
    }
    .left-fifth{
        left: 325px !important;
    }
    .left-sixth{
        left: 406px !important;
    }
}

/* MOBILE VIEW 425PX */
@media screen and (max-width: 426px){
    
    .left-second{
        left:67px!important;
    }
    .left-third{
        left: 130px !important;
    }
    .left-fourth{
        left: 191px !important;
    }
    .left-fifth{
        left: 255px !important;
    }
    .left-sixth{
        left: 317px !important;
    }
}