.career-item{
    flex: 1;
    padding: 18px 30px 40px 30px;
    border: 1px solid #0000004D;
    border-radius: 16px;
    transition: 1s ease;
    .career-item-header{
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .header-text{
            width: 88%;
            p{
                margin-top: 12px;
                margin-bottom: 20px;
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
    .career-item-body{
        color: #00000099;
        font-size: 13px;
        width: 80%;
        ul{
            margin-left: 12px;
            li{
                margin-bottom: 10px;
            }
        }
    }
}


// TABLET VIEW
@media screen and (max-width: 769px) {
    .career-item{
        .career-item-header{
            .header-text{
                h3{
                    font-size: 13px;
                }
                p{
                    font-size: 11px;
                }
            }
        }
        .career-item-body{
            font-size: 12px;
            width: 100%;
        }
    }
}