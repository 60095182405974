.admission-application-details{
    width: 85%;
    margin: auto;
    .application-details{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        padding: 20px;
    }
}
.pagination-container{
    display: flex;
    padding: 20px;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
    list-style-type: none;
}
.page-link{
    display: flex;
    border: 0.3px solid #00000066;
    width: 30px !important;
    cursor: pointer;
    color: #00000066;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    height: 30px;
}
.active-page{
    background-color: #369bff7e;
    color: #0061c2;
    width: 30px !important;
    cursor: default !important;
    border-radius: 100%;
    height: 30px;
}
