#accordion-item{
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    border-radius: 16px;
    margin-bottom: 16px;
    transition:all .4s ease;
    
}
.accordion-item-open{
    background-color: white;
    border: 0.5px solid #00000066;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
    // height:171px;
    .accordion-item-title{
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        margin-bottom:24px;
        gap: 5px;
        cursor:pointer;
        p{
            color: #021D53;
            width: 95%;
        }
        svg{
            font-size: 20px;
            width: 5%;
        }
    }
    .accordion-item-answer{
        font-size: 13.33px;
        font-weight: 500;
        line-height: 16.25px;
        color: #00000099;
    }
}
.accordion-item-close{
    background-color: #D9E5FC;
    // height:75px;
    .accordion-item-title{
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        gap: 10px;
        cursor:pointer;
        p{
            width: 95%;
        }
        svg{
            font-size: 20px;
            width:5%
        }
    }
    .accordion-item-answer{
        display: none;
        
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    .accordion-item-title{
        p{
            font-size: 13px;
        }
    }
}