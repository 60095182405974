
.carousel-container{
    width: 100%;
    height: 121vh;
    display: flex;
    position: relative;
    overflow: hidden;
    
    svg{
        font-size: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        color: white;
        z-index: 2;
    }
    .wrapper{
        height: 100%;
        display: flex;
        transition: all 1s ease;
        .slide{
            width: 100vw;
            display: flex;
            align-items: center;
            // background-image: url("../../assets/carousel/image2.png"), linear-gradient(to right,rgba(32, 26, 1,.521) 45%,rgba(255, 255, 255, 0.212) 100%);
            background-repeat: no-repeat;
            background-position: center;
            // background: linear-gradient(to right, rgba(32, 26, 1,1) 0%, rgba(255, 255, 255, 0.212) 100%);
            background-blend-mode: overlay;
            // -webkit-background-size: cover;
            // -moz-background-size: cover;
            // -o-background-size: cover;
            background-size: cover;
            .carousel-text{
                color: white;
                width: 90%;
                margin: auto;
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                justify-content: center;
                margin-top: 510px;
                h3{
                    font-size: 39.81px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                h1{
                    font-size: 82.56px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }
                
            }
        }
    }
}

// LARGE LAPTOP VIEW
@media screen and (max-width: 1441px) {
    .carousel-container{
        .wrapper{
            .slide{
                .carousel-text{
                    margin-top: 430px;
                }
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    .carousel-container{
        .wrapper{
            .slide{
                .carousel-text{
                    color: white;
                    margin-top: 350px;
                    h3{
                        font-size:17.81px;
                    }
                    h1{
                        font-size: 50.56px;
                        line-height: 60px;
                    }
                    
                }
            }
        }
    }
}


// TABLET VIEW
@media screen and (max-width: 769px) {
    .carousel-container{
        .wrapper{
            .slide{
                .carousel-text{
                    h3{
                        font-size:25.81px !important;
                    }
                    h1{
                        font-size: 55.56px;
                    }
                }
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    .carousel-container{
        .wrapper{
            .slide{
                .carousel-text{
                    color: white;
                    width: 74%;
                    margin: auto;
                    
                    h3{
                        font-size:17.81px;
                    }
                    h1{
                        font-size: 50.56px;
                        line-height: 60px;
                    }
                    
                }
            }
        }
    }
}


