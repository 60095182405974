#message-section{
    padding: 70px 90px;
    .message-section-header{
        margin-bottom: 50px;
        h2{
            font-size: 39.81px;
            margin-bottom: 10px;
            color: var(--secondary);
        }
        .underline{
            width: 10%;
            height: 2px;
            background-color: var(--faded-black);
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1024px){
    #message-section{
        padding: 30px;
    }
}