#faq-section{
    .frequently-asked-questions{
        display: flex;
        gap: 24px;
    }
}

@media screen and (max-width: 426px) {
    #faq-section{
        .frequently-asked-questions{
            flex-direction: column;
        }
    }
}