#admission-section{
    h3{
        color: #021D53;
    }
    p{
        color: #000000B2;
    }
    .admission-section-header{
        display: flex;
        width: 100%;
        align-items: center;
        .admission-section-header-left{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 46px;
            h2{
                font-size: 59.23px;
                font-weight: 700;
                color: #021D53;
            }
            .apply-here-button{
                padding:20px 50px;
                background-color: #021D53;
                color: #fff;
                width: max-content;
                border-radius: 10px;
                &:hover{
                    cursor: pointer;
                    background-color: #032770;
                }
            }
        }
        .admission-section-header-right{
            flex: 2;
            display: flex;
            overflow: hidden;
            align-items: center;
            justify-content: center;
            .image-container{
                display: flex;
                overflow: hidden;
                height: 496px;
                .image-slide{
                    flex: 1;
                    img{
                        transition: all 1s ease;
                        border-radius: 30px;
                    }
                }
            }
        }
    }
    .admission-section-body{
        margin-top: 20px;
        
        display: flex;
        flex-direction: column;
        gap: 25px;
        .application-requirement-div{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: justify;
            ul{
                margin-left: 20px;
            }
        }
        .admission-process{
            border-radius: 20px;
            border: 1px solid #5BA2F433;
            padding: 31px 20px;
            .admission-process-title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                
            }
            .admission-process-body{
                margin-top: 20px;
                width: 80%;
                text-align: justify;
                .admission-process-intro{
                    margin-bottom: 10px;
                }
                ul{
                    margin-left: 25px;
                }
                .admission-process-item{
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }
}


// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #admission-section{
        .admission-section-header{
            .admission-section-header-left{
                h2{
                    font-size: 50.23px;
                }
                .apply-here-button{
                    padding: 15px 35px;
                }
            }
            .admission-section-header-right{
                .image-container{
                    height:380px;
                    img{
                        height:380px;
                    }
                }
            }
        }
        .admission-section-body{
            .admission-process{
                .admission-process-body{
                    width: 95%;
                }
            }
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    #admission-section{
        h3{
            font-size: 15px;
        }
        p{
            font-size: 14px;
        }
        ul{
            font-size: 14px;
        }
        .admission-section-header{
            .admission-section-header-left{
                h2{
                    font-size: 40px;
                }
                .apply-here-button{
                    padding: 10px 25px;
                }
            }
            .admission-section-header-right{
                .image-container{
                    height:310px;
                    img{
                        height:310px;
                    }
                }
            }
        }
        .admission-section-body{
            .admission-process{
                .admission-process-body{
                    width: 95%;
                }
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    #admission-section{
        h3{
            font-size: 13px;
        }
        p{
            font-size: 12px;
        }
        ul{
            font-size: 12px;
        }
        .admission-section-header{
            .admission-section-header-left{
                gap: 10px;
                h2{
                    font-size: 42px;
                }
                .apply-here-button{
                    padding: 10px 25px;
                }
            }
            .admission-section-header-right{
                display: none;
            }
        }
        .admission-section-body{
            .admission-process{
                .admission-process-body{
                    width: 95%;
                }
            }
        }
    }
}