#login-page{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .login-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 55px;
        width: 30%;
        
        .school-logo{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 12px;
            .school-name{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .school-main-name{
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    .anell{
                        font-size: 49.81px;
                        font-weight: 700;
                        color: var(--secondary);
                    }
                    .school-of-excellence{
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        color: var(--main);
                        font-weight: 600;
                    }
                }
                .school-motto{
                    font-style: italic;
                    color: var(--secondary);
                    font-weight: 600;
                }
            }
            .logo-underline{
                width: 182px;
                height: 1px;
                background-color: var(--secondary);
            }
        }
        .login-form{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 32px;
            .input-container{
                border: 0.4px solid #00000072;
                border-radius: 8px;
                padding: 10px 20px;
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;
                svg{
                    color: #021D53;
                }
                &:has(input:focus){
                    border: 1px solid #021D53;
                }
                input{
                    border: none;
                    width: 100%;
                    color: #021D53;
                    &:focus{
                        outline: none;
                    }
                    &::placeholder{
                        color: #021D53;
                    }
                    &:-internal-autofill-selected{
                        background-color: transparent!important;
                    }
                }
            }
            input[type=submit]{
                padding: 10px 20px;
                background-color: #021D53;
                color: white;
                border: none;
                border-radius: 8px;
                transition: .3s ease-in-out;
                &:hover{
                    cursor: pointer;
                    background-color: #021d53e3;
                }
            }
            .rotating-lines{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #login-page{
        .login-section{
            width: 40%;
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 826px){
    #login-page{
        .login-section{
            width: 60%;
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    #login-page{
        .login-section{
            width: 70%;
        }
    }
}