.top-section{
    display: flex;
    
    .school-information{
        color: var(--white);
        // background-color: var(--main);
        background: linear-gradient(to right, #221B2D, #020D24);
        flex: 9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        
        .school-address{
            display: flex ;
            gap: 50px;
            
            .school-mail{
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
    }
    .social-media{
        padding: 20px 40px 20px 10px;
        flex: 1;
        background-color: var(--white) ;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .social-media-icon{
            padding: 6px 6px 3px;
            background-color: var(--black);
            border-radius: 8px;
            transition: all .2s ease-in-out;
            svg{
                color: white;
                font-size: 20px;
            }
            &:hover{
                background-color: var(--secondary);
                cursor: pointer;
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    .top-section{
        display: none;
    }
}

// TABLET
@media screen and (max-width: 821px){
    .top-section{
        .school-information{
            padding: 20px 20px;
            .school-address{
                gap: 10px;
            }
        }
        .social-media{
            padding: 20px 20px 20px 10px;
        }
    }
}

// LAPTOP 1024
@media screen and (max-width: 1188px) {
    .top-section{
        .school-information{
            padding: 20px 20px;
            
            font-size: 12px;
        }
        .social-media{
            
            gap: 14px;
            .social-media-icon{
                padding: 3px 5px 2px;
                svg{
                    color: white;
                    font-size: 14px;
                }
                &:hover{
                    background-color: var(--main);
                    cursor: pointer;
                }
            }
        }
    }
}

