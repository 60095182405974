#school-classes{
    .classes-list-section{
        .classes-list{
            .class-level{
                .class-level-title{
                    font-size: 18px;
                    font-weight: 600;
                }
                .class-level-body{
                    display: flex;
                    flex-wrap: wrap;
                    gap:86px;
                    padding: 36px 0;
                    .school-class{
                        background-color: #5BA2F433;
                        padding: 12px 41px;
                        
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                        font-size: 14px;
                        text-transform: capitalize;
                        width: max-content;
                    }
                }
            }
        }       
    }
}