.mission-vision{
    
    padding: 70px 85px;
    
    .mission-vision-header{
        h1{
            font-size: 47.78px;
            font-weight: 700;
            color: var(--secondary);
            width: 35%;
            line-height: 43px;
        }
        .underline{
            height: 6px;
            width: 93px;
            background-color: var(--faded-black);
            margin-top: 10px;
        }
    }
    .statements{
        margin-top: 70px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .statement{
            flex: 1;
            background: linear-gradient(to right, #221B2D 10%, #221B2D 85%);
            border-radius: 20px;
            padding: 40px 39px;
            color: white;
            h4{
                margin-bottom: 20px;
                font-size: 23.04px;
                line-height: 28.09px;
            }
            p{
                font-size: 14px;
            }
            .lists{
                font-size: 14px;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                margin-left: 15px;
            }
        }
    }
}

// LAPTOP VIEW
@media only screen and (max-width:1025px){
    .mission-vision{
        padding: 70px 54px;
        .mission-vision-header{
            h1{
                width: 60%;
            }
        }
        .statements{
            .statement{
                padding: 40px 15px;
                p{
                    font-size: 11px;
                }
                .lists{
                    font-size: 11px;
                }
            }
        }
    }
}

// TABLET VIEW
@media only screen and (max-width:769px){
    .mission-vision{
        padding:70px 15px;
        .mission-vision-header{
            h1{
                font-size: 39.78px;
            }
        }
        .statements{
            gap: 15px;
            margin-top: 53px;
            .statement{
                padding: 25px 15px;
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    .mission-vision{
        padding:70px 32px;
        .mission-vision-header{
            h1{
                width: 100%;
                font-size: 32.78px;
            }
        }
        .statements{
            flex-direction: column;
            .statement{
                padding: 38px 25px;
                p{
                    font-size: 14px;
                }
                .lists{
                    font-size: 14px;
                }
            }
        }
    }
}