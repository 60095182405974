#department-content{
    .departments-details{
        margin-top: 60px;
        margin-bottom: 60px;
        border: 1px solid #00000066;
        .departments-tabs{
            background-color: var(--light-blue);
            display: flex;
            width: 100%;
            .department-tab{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 22px 42px;
                transition: .4s ease;
                border-width: 0px 1px 1px 0px;
                border-style: solid;
                border-color: #00000066;
                &:hover{
                    background-color: var(--secondary);
                    color: white;
                    cursor: pointer;
                }

            }
            .active-department-tab{
                background-color: var(--secondary);
                color: white;
            }
        }
        
        .department-tab-information{
            padding: 53px 70px;
            .department-tab-details{
                ul{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }
    }    
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #department-content{
        .departments-details{
            
            .departments-tabs{
                
                .department-tab{
                    
                    padding: 10px 20px;
                   
    
                }
                
            }
        }    
    }
}

// TABLET VIEW
@media screen and (max-width: 769PX){
    #department-content{
        .departments-details{
            
            .departments-tabs{
                
                .department-tab{
                    
                    padding: 8px 14px;
                   
    
                }
                
            }
        }    
    }
}

// MOBILE VIEW
// TABLET VIEW
@media screen and (max-width: 426px){
    #department-content{
        .departments-details{
            
            .departments-tabs{
                
                overflow: scroll;
                
            }
        }    
    }
}