#loading{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    background-color: #fff;
    .box{
        position: relative;
        width: 300px;
        height: 300px;
        background-color: rgba(0,0,0,0);
        border-radius: 50%;
        overflow: hidden;
        &::before{
            content: "";	
            position: absolute;
            inset: -10px 100px;
            background-color: #162052;
            transition: 0.5s;
            animation: animate 1s linear infinite;
        }
        &::after{
            content:"";
            position: absolute;
            inset: 6px;
            background-color: #fff;
            border-radius: 100%;
            z-index: 1;
        }
        .content{
            position: absolute;
            inset: 30px;
            border: 6px solid #070a1c;
            z-index: 3;
            border-radius: 50%;
            overflow: hidden;
            background-color: white;
        }
        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            height: 140px;
        }
    }
}

@keyframes animate {
    0%{
        transform: rotate(0deg);
    }
    0%{
        transform: rotate(360deg);
    }
}