.section-header{
    margin-bottom: 50px;
    h2{
        font-size: 39.81px;
        margin-bottom: 10px;
        width: 24%;
        color: var(--secondary);
    }
    .underline{
        width: 157px;
        height: 2px;
        background-color: var(--faded-black);
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    .section-header{

        h2{
            width: 40%;
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    .section-header{
        margin-bottom: 20px;
        h2{
            width: 50%;
        }
    }
}

// MOBILE VIEW
@media screen and (max-width:426px){
    .section-header{
        h2{
            width: 100%;
        }
    }
}