.custom-form{
    margin-top: 20px;
    .custom-form-row{ 
        display: flex;
        gap: 50px;
        margin-top: 14px;
        .custom-form-col{
            display: flex;
            flex-direction: column;
            gap: 5px;
            flex: 1;
            .custom-form-control{
                padding: 10px;
                border: 1px solid #4141414d;
                color: #414141e1;
                border-radius: 5px;
                &:focus{
                    outline: none;
                }
            }
            
        }
    }

    .news-details-images{
        justify-content: center;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .news-detail-image{
            height: 260px;
            width: 30%;
            overflow: hidden;
            position: relative;
            
            img{
                object-fit: cover;
                max-width: 100%;
            }
            .news-detail-image-overlay{
                position: absolute;
                background: rgba(0,0,0,0.5);
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                display: none;
                align-items: center;
                justify-content: center;
                transition: .5s ease-in-out;
                gap: 15px;
            }
            &:hover > .news-detail-image-overlay{
                display: flex;
            }
        }
    }
    .form-action-buttons{
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-block-start: 20px;
    }
}


@media screen and (max-width: 769px){
    .custom-form{
        .custom-form-row{
            flex-direction: column;
            gap: 10px;
        }
    }
}