#admission-form {
  padding: 23px 50px;
  border-top: 1px solid #d9d9d9;
  .admission-form-tabs {
    display: flex;
    background-color: #021d53;
    flex-wrap: wrap;
    .admission-form-tab {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 9px;
      padding: 12px 18px;
      background-color: #4c556180;
      .number {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: #a5a2ab87;
        color: #7c7e83;
      }
      .admission-form-tab-name {
        color: #c2b6b6c2;
      }
    }
    .admission-form-tab-active {
      background-color: #5ba2f433;
      .number {
        background-color: white;
        color: #021d53;
      }
      .admission-form-tab-name {
        color: #fff;
      }
    }
  }
  .admission-form-details {
    margin-top: 32px;
    .admission-form-info {
      background-color: #5ba2f433;
      padding: 23px 18px;
      margin-bottom: 30px;
      .form-info-title {
        color: #000000b2;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .form-info-description {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .main-admission-form {
    // text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 80%;
    .form-info {
      font-style: italic;
      color: #021d53;
    }
    .image-upload-description {
      font-size: 13.33px;
      width: 80%;
      margin: auto;
    }
    .section-title {
      color: #00000080;
      font-size: 13.33px;
      margin-left: 90px;
      margin-top: 30px;
    }
    .main-admission-form-row {
      display: flex;
      gap: 33px;
      .admission-clauses-title {
        color: #021d53 !important;
        font-weight: bold;
        font-size: 20px;
      }
      label {
        flex: 1;
        text-align: right;
        font-weight: 600;
        font-size: 13.33px;
      }
      .main-admission-form-right {
        flex: 4;
        .admission-clauses {
          color: #021d53;
          .admission-clause {
            margin-bottom: 10px;
            text-transform: none !important;
            text-align: justify;
          }
        }
        .main-admission-form-multiple-inputs {
          display: flex;
          flex-direction: column;
          gap: 7px;
        }
        .main-admission-form-input {
          input[type="radio"] {
            margin-left: 20px;
          }
          select,
          input[type="text"],
          input[type="email"],
          textarea {
            padding: 8px 12px 8px 12px;
            border: 0.5px solid #00000080;
            border-radius: 6px;
            color: #040404cc;
            width: 100%;
            // text-transform: uppercase;
            &::placeholder {
              color: #04040499;
            }
          }
          .upload-button {
            display: flex;
            gap: 7px;
            padding: 10px 20px;
            background-color: #021d5399;
            color: #ffffff;
            border-radius: 6px;
            width: max-content;
            cursor: pointer;
          }
          .uploaded-file {
            font-size: 12px;
          }
          .file-upload-info {
            font-size: 13.33px;
            font-weight: 400;
            margin-top: 15px;
          }
        }
        .radio-inputs {
          display: flex;
          align-items: center;
          gap: 200px;
          .radio-input {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 13px;
            color: #04040499;
          }
        }
        .date-of-birth {
          display: flex;

          select {
            border-radius: 0px;
          }
        }
      }
    }
    .note-to-consider {
      background-color: #deecfd;
      padding: 20px;
      .note-title {
        margin-bottom: 10px;
      }
      .notes {
        margin-left: 20px;
        .note {
          margin-bottom: 10px;
        }
      }
    }
    .align-center {
      align-items: center;
    }
    .form-bottom {
      display: flex;
      align-items: center;
      gap: 33px;
      .empty-section {
        flex: 1;
      }
      .form-save-buttons {
        display: flex;
        gap: 20px;
        margin-top: 30px;
        flex: 4;
        .save-button {
          padding: 12px;
          width: 206px;
          background-color: #021d53;
          border-radius: 6px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}

#admission-form-preview {
  .preview-form-header {
    border-bottom: 2px solid #021d53;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .preview-form-title {
      font-weight: 600;
      color: #021d53;
      font-size: 40px;
      text-transform: capitalize;
    }
    .school-logo {
      padding: 28px 0px;
      display: flex;
      gap: 10px;

      img {
        height: 70px;
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .main-text {
          display: flex;
          gap: 5px;
          align-items: center;
          h2 {
            font-size: 49.81px;
            font-weight: 700;
            color: var(--secondary);
          }
          span {
            display: flex;
            flex-direction: column;
            gap: 2px;
            color: var(--main);
            font-weight: 600;
          }
        }
        .motto {
          font-style: italic;
          color: var(--secondary);
          font-weight: 600;
        }
      }
    }
  }
  .preview-form-body {
    padding: 20px;
    .preview-form-section {
      margin-bottom: 20px;
      border-bottom: 1px solid #00000080;
      padding-bottom: 20px;
      .preview-form-section-title {
        font-size: 13.33px;
        padding: 8px 35px;
        font-weight: bold;
        border: 0.5px solid #000000;
        margin-bottom: 22px;
        width: max-content;
      }
      .preview-form-details {
        .preview-form-information {
          margin-top: 20px;
          .preview-form-information-row {
            display: flex;
            margin-top: 10px;
            .preview-form-information-item {
              flex: 1;
              .field {
                label {
                  font-weight: 600;
                }
                .data {
                  // text-transform: uppercase;
                  // font-size: 20px;
                  // font-weight: 600;
                }
              }
            }
          }
        }
      }
      .subsection {
        .subsection-title {
          margin-top: 40px;
          font-size: 15px;
          font-weight: 600;
          color: #00000080;
        }
      }
    }
    .admission-clauses {
      .admission-clauses-header {
        color: #021d53;
      }
      .admission-clauses-list {
        margin-left: 20px;
        font-size: 18px;
        color: #021d53;
        .admission-clause {
          margin-bottom: 10px;
          text-align: justify;
        }
      }
    }
    .note-to-consider {
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: #deecfd;
      padding: 20px;
      .note-title {
        margin-bottom: 10px;
      }
      .notes {
        margin-left: 20px;
        .note {
          margin-bottom: 10px;
        }
      }
    }
    .declaration {
      margin-top: 20px;
      .declaration-header {
        color: #021d53;
      }
      .declaration-body {
        font-size: 20px;
      }
      .declaration-bottom {
        margin-top: 15px;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        .declaration-bottom-item {
          flex: 1;
          display: flex;
          gap: 10px;
          label {
            font-size: 20px;
          }
          input {
            width: 100%;
            border: none;
            border-bottom: 1px solid black;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .official-use {
      margin-top: 70px;
      .official-use-header {
        color: #021d53;
      }
      .official-use-form {
        .official-use-row {
          margin-top: 15px;
          display: flex;
          gap: 20px;
          justify-content: space-between;
          .official-use-col {
            flex: 1;
            display: flex;
            gap: 10px;
            align-items: flex-end;
            label {
              font-size: 20px;
              flex: 1;
            }
            input {
              flex: 2.5;
              
              border: none;
              border-bottom: 1px solid black;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
    .school-details{
      margin-top:60px ;
      border-top: 1px solid #021d53;
      display: flex;
      padding: 20px 0px;
      justify-content: space-between;
      font-size: 16px;
      .school-detail{
        
        p{
          display: flex;
          align-items: center;
          gap: 5px;
          margin-bottom: 5px;
          svg{
            font-size: 18px;
          }
        }
      }
    }
  }
  .preview-form-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    .preview-form-buttons {
      display: flex;
      gap: 20px;
      .preview-form-button {
        padding: 12px;
        min-width: 206px;
        background-color: #021d53;
        border-radius: 6px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

// TABLET VIEW
@media screen and (max-width: 769px) {
  #admission-form {
    .main-admission-form {
      width: 100%;
    }
  }
}

// MOBILE VIEW
@media screen and (max-width: 426px) {
  #admission-form {
    .admission-form-tabs {
      flex-direction: column;
    }
    .main-admission-form {
      .image-upload-description {
        width: 100%;
      }
      .section-title {
        margin-left: 0px;
      }
      .main-admission-form-row {
        flex-direction: column;
        gap: 5px;

        label {
          text-align: left;
        }

        .main-admission-form-right {
          width: 100%;
          .radio-inputs {
            gap: 66px;
          }
        }
      }
      .align-center {
        align-items: flex-start;
      }
      .form-bottom {
        flex-direction: column;
        gap: 0px;
        .form-save-buttons {
          gap: 8px;
          .save-button {
            width: max-content;
            font-size: 12px;
          }
        }
      }
    }
  }
}
