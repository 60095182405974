#choose-anell {
  background-image: url("../../../assets/aboutpage/chooseanell.png"),
    linear-gradient(to right, #021d53d3 65%, #021d53d3 100%);
  background-blend-mode: overlay;
  padding: 50px 10px;
  position: relative;
  .arrow-down-1,
  .arrow-down-2,
  .arrow-up-1,
  .arrow-up-2 {
    position: absolute;
  }
  .arrow-down-1 {
    top: 150px;
    left: 100px;
  }
  .arrow-up-1 {
    top: 180px;
    left: 145px;
  }
  .arrow-up-2 {
    bottom: 150px;
    right: 100px;
  }
  .arrow-down-2 {
    bottom: 180px;
    right: 145px;
  }
  .choose-anell-content {
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    .choose-anell-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      h2 {
        font-size: 33.18px;
        font-weight: 500;
        text-align: center;
        color: white;
      }
      .underline {
        width: 157px;
        height: 1px;
        background-color: #ffffff80;
      }
    }
    .features {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      .feature-display {
        background-color: white;
        border-radius: 20px;
        padding: 92px 100px;
        position: relative;
        height: 373px;

        .feature-display-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .feature-display-top {
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
              font-size: 33.18px;
              color: var(--secondary);
              margin-bottom: 10px;
              font-weight: 500;
              text-align: center;
            }
            .underline {
              width: 83px;
              height: 2px;
              background-color: black;
            }
          }
          .feature-display-text {
            margin-top: 30px;
            line-height: 19.2px;
            text-align: center;
          }
        }
      }
      .feature-icons {
        display: flex;
        gap: 30px;
        cursor: pointer;
        position: relative;
        margin-top: 16px;
        .droparrow{
            position: absolute;
            top: -63px;
            left: 22px;
            font-size: 30px;
            color: white;
        }
        .feature-icon {
          height: 80px;
          width: 80px;
          background-color: white;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .sub-text {
      width: 720px;
      margin-top: 60px;
      p {
        text-align: center;
        line-height: 19.2px;
        font-weight: 300;
        color: white;
        font-style: italic;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  #choose-anell {
    .arrow-down-1 {
      top: 150px;
      left: 30px;
    }
    .arrow-up-1 {
      top: 180px;
      left: 72px;
    }
    .arrow-down-2 {
      bottom: 180px;
      right: 72px;
    }
    .arrow-up-2 {
      bottom: 150px;
      right: 30px;
    }
    .choose-anell-content {
      width: 73%;
      .choose-anell-header {
        h2 {
          font-size: 24.18px;
        }
      }
      .features {
        .feature-display {
          padding: 59px 47px;
        }
        .feature-icons {
          .feature-icon {
            height: 70px;
            width: 70px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  #choose-anell {
    .choose-anell-content {
      .choose-anell-header {
        h2 {
          font-size: 20.18px;
        }
      }
      .features {
        .feature-display {
          .feature-display-content {
            .feature-display-top {
              h3 {
                font-size: 27.18px;
              }
            }
            .feature-display-text {
              font-size: 14px;
            }
          }
        }
        .feature-icons {
          .feature-icon {
            height: 50px;
            width: 50px;
            img {
              height: 24px;
            }
          }
        }
      }
      .sub-text {
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  #choose-anell {
    .arrow-down-1 {
      top: 140px;
      left: 23px;
      height: 25px;
    }
    .arrow-up-1 {
      top: 153px;
      left: 41px;
      height: 25px;
    }
    .arrow-down-2 {
      bottom: 180px;
      right: 72px;
      height: 25px;
    }
    .arrow-up-2 {
      bottom: 168px;
      right: 55px;
      height: 25px;
    }
    .choose-anell-content {
      width: 100%;
      .features {
        .feature-display {
          padding: 35px 25px;
          .feature-display-content {
            .feature-display-top {
              h3 {
                font-size: 23.18px;
              }
            }
            .feature-display-text {
              font-size: 12px;
            }
          }
        }
        .feature-icons {
          gap: 12px;
        }
      }
      .sub-text {
        width: 100%;
        p {
          font-size: 12px;
        }
      }
    }
  }
}
