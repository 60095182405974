#dashboard-navbar{
    display: flex;
    justify-content: space-between;
    background-color: #011131;
    padding: 21px 130px;
    .dashboard-navigations{
        .dashboard-menu-list{
            display: flex;
            gap: 10px;
            list-style: none;
            .nav-items-menu-list{
                display: flex;
                gap: 30px;
            }
        }
        .notification-user-logout{
            display: none !important;
        }
    }
    .dashboard-navbar-search{
        color: #FFFFFF80;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0 10px;
        border: 1px solid #FFFFFF4D;
        border-radius: 6px;
        input[type=text]{
            padding: 10px 0;
            background: transparent;
            border: none;
            color: #FFFFFF80;
            &:focus{
                outline: none;
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1026px){
    #dashboard-navbar{
        padding: 20px;
    }
}


// TABLET VIEW
@media screen and (max-width: 826px){
    #dashboard-navbar{
        padding: 0;
        .dashboard-navigations{
            flex: 1;
            .dashboard-menu-list{
                display: flex;
                flex-direction: column;
                align-items: inherit;
                justify-content: flex-start;
                position: fixed;
                top: 71px;
                right: -340px;
                width: 250px;
                height: 90vh;
                background-color: #011131;
                box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
                padding: 40px 20px;
                transition: 0.3s ease-in-out;
                z-index: 9999999;
                .nav-items-menu-list{
                    flex-direction: column;
                    gap: 5px;
                }
            }
            .active-navbar{
                right:0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .notification-user-logout{
                    display: flex !important;
                    flex-direction: column;
                    width: 100%;
                    align-items: flex-end;
                    .notification{
                        display: none;
                        
                    }
                    .user{
                        .username-position{
                            
                            .username{
                                color: #fff;
                                
                            }
                        }
                        .user-image{
                            height: 50px;
                            width: 50px;
                        }
                    }
                    .logout-btn{
                        margin-left: 0px;
                        border: 1px solid #fff;
                        justify-content: center;
                        color: #fff;
                        width: 100%;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .dashboard-navbar-search{
            display: none;
        }
    }
}