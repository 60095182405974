#dashboard-topbar{
    padding: 21px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .school-logo-topbar {
        display: flex;
        gap: 10px;
        img {
          height: 70px;
        }
        .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .main-text {
            display: flex;
            gap: 5px;
            align-items: center;
            h2 {
              font-size: 49.81px;
              font-weight: 700;
              color: var(--secondary);
            }
            span {
              display: flex;
              flex-direction: column;
              gap: 2px;
              color: var(--main);
              font-weight: 600;
            }
          }
          .motto {
            font-style: italic;
            color: var(--secondary);
            font-weight: 600;
          }
        }
    }
    #portal-mobile {
      display: none;
    }
  }
  
.notification-user-logout{
    display: flex;
    align-items: center;
    gap: 17px;
    .notification{
        box-shadow: 0px 20px 50px 0px #BF156C0D;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px;
        border-radius: 100%;
        position: relative;
        svg{
            font-size: 32px;
            color: #A098AE;
        }
        .notification-dot{
            position: absolute;
            height: 8px;
            width: 8px;
            background-color: #4D44B5;
            border-radius: 100%;
            top: 12px;
            right: 12px;
        }
    }
    .user{
        display: flex;
        align-items: center;
        gap: 10px;
        .username-position{
            text-align: right;
            font-family: 'Poppins', sans-serif;
            .username{
                color: #303972;
                font-size: 16px;
                font-weight: 600;
                
            }
            .position{
                color: #A098AE;
                font-size: 14px;
                
            }
        }
        .user-image{
            height: 40px;
            width: 40px;
            cursor: pointer;
            background-color: #ffffff;
            border-radius: 100%;
            border: 1px solid #ccc;
            img{
              height: inherit;
            }
        }
    }
    .logout-btn{
        margin-left: 24px;
        border: 1px solid #0764EC;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 15px 35px;
        gap: 5px;
        font-size: 15px;
        color: #0764EC;
        &:hover{
            cursor: pointer;
        }
    }
}
// TABLET VIEW
@media screen and (max-width: 826px) {
    #dashboard-topbar{
        padding: 21px 15px;
        #portal-mobile {
          margin-top: 15px;
          margin-left: 110px;
          display: block;
        }
        .school-logo-topbar {
            img {
              height: 50px;
            }
            .text {
              .main-text {
                h2 {
                  font-size: 25px;
                }
                span {
                  font-size: 8px;
                }
              }
              .motto {
                font-size: 12px;
              }
            }
          }
        .notification-user-logout{
            display: none;
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px) {
    #dashboard-topbar{
        padding: 15px;
        .school-logo-topbar {
            img {
              height: 50px;
            }
            .text {
              .main-text {
                h2 {
                  font-size: 25px;
                }
                span {
                  font-size: 8px;
                }
              }
              .motto {
                font-size: 12px;
              }
            }
          }
        .notification-user-logout{
            display: none;
        }
    }
    .notification-user-logout{
      
    }
}