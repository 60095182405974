#anell-history{
    padding: 100px 171px;
    .history-header{
        margin-bottom: 30px;
        h1{
            margin-bottom: 10px;
            font-size: 39.81px;
            color: var(--secondary);
        }
        .underline{
            width: 157px;
            height: 2px;
            background-color: var(--faded-black);
        }
    }
    .history-content{
        p, li{
            color: var(--secondary);
            font-size: 19.2px;
            line-height: 23.4px;
        }
        .history-text{
            margin-bottom: 25px;
            text-align: justify;
            line-height: 28px;
        }
        .acronym{
            p{
                margin-bottom:10px;
            }
            ul{
                list-style: none;
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    #anell-history{
        padding: 80px 98px;
        .history-content{
            p, li{
                font-size: 14.2px;
            }
        }
    }
}


@media screen and (max-width: 769px) {
    #anell-history{
        padding: 45px 38px;
    }
}