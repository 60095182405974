.modal-overlay{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.342);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    padding: 40px;
    .modal-container{
        border-radius: 20px;
        border: 3px solid #021D5366;
        padding: 28px 41px;
        background-color: white;
        z-index: 9999999999999;
        .close-button{
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
        }
        .admission-modal-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;
            width: 85%;
            margin: auto;
            .school-logo{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 11px;
                img{
                    height: 100px;
                }
                .school-name{
                    .school-main-name{
                        font-size: 68.8px;
                        line-height: 50.87px;
                        font-weight: 700;
                    }
                    .school-sub-name{
                        font-size: 13.8px;
                    }
                }
            }
            .admission-modal-title{
                background-color: #021D53;
                width: 100%;
                color: white;
                text-align: center;
                font-size: 23.04px;
                padding: 8px;
                border-radius: 8px;
                font-weight: 500;
            }
            .admission-modal-text{
                text-align: center;
                font-weight: 600;
            }
            .admission-enquiry-form{
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;
                .admission-enquiry-form-row{
                    display: flex;
                    gap: 30px;
                    .admission-enquiry-form-input{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        flex: 1;
                        label{
                            font-weight: 500;
                        }
                        input[type=text],input[type=email],input[type=date],select{
                            padding: 11px 12px;
                            border: 0.5px solid #00000054;
                            border-radius: 6px;
                            &:focus{
                                outline: 1px solid #3995FF;
                            }
                        }
                    }
                }
                input[type=submit]{
                    background-color: #021D53;
                    padding: 13px;
                    color: white;
                    border: none;
                    border-radius: 6px;
                    font-size: 20px;
                    transition: .5s ease-in-out;
                    &:hover{
                        cursor: pointer;
                        background-color: #001a4dd9;
                    }
                }
                .form-bottom-text{
                    text-align: center;
                    a{
                        color: #3995FF;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
// LAPTOP VIEW
@media screen and (max-width: 1025px){
    .modal-container{
        .admission-modal-container{
            gap: 17px!important;
            width: 80%!important;
            .school-logo{
                img{
                    height: 70px!important;
                }
                .school-name{
                    .school-main-name{
                        font-size: 54.8px!important;
                        line-height: 40.87px!important;
                    }
                    .school-sub-name{
                        font-size: 10.8px!important;
                    }
                }
            }
            .admission-modal-title{
                font-size: 17.04px!important;
            }
            .admission-enquiry-form{
                .admission-enquiry-form-row{
                    .admission-enquiry-form-input{
                        input[type=text], input[type=email]{
                            padding:10px 12px!important;
                        }
                    }
                }
                input[type=submit]{
                    padding: 10px !important;
                    font-size: 15px!important;
                }
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    .modal-overlay{
        .modal-container{
            padding: 10px 30px;
        }
        .admission-modal-container{
            gap: 10px!important;
            width: 100%!important;
            .school-logo{
                gap: 5px;
                img{
                    height: 40px!important;
                }
                .school-name{
                    .school-main-name{
                        font-size: 36px!important;
                        line-height: 24px!important;
                    }
                    .school-sub-name{
                        font-size: 8px!important;
                    }
                }
            }
            .admission-modal-title{
                font-size: 10px!important;
            }
            .admission-modal-text{
                font-size: 10px;
            }
            .admission-enquiry-form{
                gap: 9px!important;
                .admission-enquiry-form-row{
                    flex-direction: column;
                    gap: 9px!important;
                    .admission-enquiry-form-input{
                        gap: 2px!important;
                        label{
                            font-size: 13px;
                        }
                        input[type=text],input[type=email]{
                            padding: 10px 12px !important;
                            font-size: 12px;
                        }
                    }
                }
                input[type=submit]{
                    padding: 10px !important;
                    font-size: 13px !important;
                }
                .form-bottom-text{
                    font-size: 12px !important;
                }
            }
        }
    }
}