.no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    img{
        height: 300px;
        margin-bottom: 20px;
    }
    h3{
        font-size: 30px;
        font-weight: 600;
    }
}