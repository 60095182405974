
.navigation-bar {
  padding: 0px 20px;
  display: flex;

  .school-logo {
    flex: 3;
    padding: 28px 0px;
    display: flex;
    gap: 10px;
    img {
      height: 70px;
    }
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .main-text {
        display: flex;
        gap: 5px;
        align-items: center;
        h2 {
          font-size: 49.81px;
          font-weight: 700;
          color: var(--secondary);
        }
        span {
          display: flex;
          flex-direction: column;
          gap: 2px;
          color: var(--main);
          font-weight: 600;
        }
      }
      .motto {
        font-style: italic;
        color: var(--secondary);
        font-weight: 600;
      }
    }
  }
  .navigations {
    flex: 6;
    display: flex;
    padding-top: 50px;
    .menu-list {
      width: 95%;
      display: flex;
      list-style: none;
      font-weight: 600;
      font-size: 20px;
      justify-content: space-around;
      .menu-item {
        &:not(:last-child){
          transition: 0.2s linear;
          position: relative;
          transition: all 0.3s ease-in-out;
          transition: all 1s ease-in-out;
          &:hover {
          // border-bottom: 5px solid var(--main);
          cursor: pointer;
          color: var(--main);
          transition: all 0.3s ease-in-out;
          &::after {
            content: "";
            width: 70%;
            height: 2.5px;
            background: var(--main);
            position: absolute;
            bottom: 43px;
            left: 0px;
          }
        }
        }
        .nav-link-item {
          color: var(--secondary);
          display: flex;
          align-items: center;
          gap: 8px;
        }
        &:last-child{
          .nav-link-item{
            background-color: var(--secondary);
            height: max-content;
            padding: 8px 21px;
            color: white;
            border-radius: 5px;
            cursor: pointer;
          }
        }
        
        .submenu {
          position: absolute;
          background: white;
          top: 86px;
          left: 2px;
          list-style: none;
          z-index: 9999;
          padding: 10px 14px;
          font-size: 16.33px;
          width: 212px;
          border-top: 4px solid;

          .submenu-item {
            transition: 0.2s linear;
            position: relative;
            padding: 10px 7px;
            transition: all 0.3s ease-in-out;
            border-left: 3px solid transparent;
            &:hover {
              border-left-color: var(--main);
            }
            // &:has(> a.active){

            //         // border-bottom: 5px solid var(--main);
            //         color: yellow;
            //         &::after{
            //             content: "";
            //             width: 70%;
            //             height: 2.5px;
            //             background: var(--secondary);
            //             position: absolute;
            //             bottom: 43px;
            //             left: 0px;
            //             transition: all 0.3s ease-in-out;
            //         }

            // }
            .sub-nav-link-item {
              color: var(--secondary);
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
          .active {
            color: var(--secondary);
            &::after {
              content: "";
              width: 0%;
              height: 2.5px;
              background: var(--secondary);
              position: absolute;
              bottom: 43px;
              left: 0px;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        .open-submenu{
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
          transition: .3s ease-in-out;
        }
        .close-submenu{
          opacity: 0;
          visibility: hidden;
          transform: translateY(-20px);
          transition: .3s ease-in-out;
        }
      }
    }
   
    .search-icon {
      font-size: 24px;
      color: gray;
      margin-left: 50px;
      transition: 0.2s linear;
      &:hover {
        cursor: pointer;
        color: var(--black);
      }
    }
  }
}

#mobile {
  display: none;
}

// LAPTOP VIEW
@media screen and (max-width: 1025px) {
  .navigation-bar {
    padding: 0px 15px;
    .school-logo {
      .text {
        .main-text {
          h2 {
            font-size: 38px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
    .navigations {
      .menu-list {
        .menu-item {
          font-size: 15px;
          &:last-child{
            .nav-link-item{
              padding: 5px;
              font-size: 12px;  
            }
          }
          .submenu {
            top: 76px;
          }
        }
      }
    }
  }
}

// TABLET VIEW
@media screen and (max-width: 835px) {
  .navigation-bar {
    .school-logo {
      img {
        height: 50px;
      }
      .text {
        .main-text {
          h2 {
            font-size: 25px;
          }
          span {
            font-size: 8px;
          }
        }
        .motto {
          font-size: 12px;
        }
      }
    }
    .navigations {
      flex: 6;
      .menu-list {
        .menu-item {
          font-size: 12px;
          .submenu{
            top: 56px;
            padding: 8px 9px;
            .submenu-item{
                font-size: 12px;
                padding: 7px 3px;
            }
          }
        }
      }
    }
  }
}

// MOBILE VIEW
@media screen and (max-width: 426px) {
  #mobile {
    margin-top: 15px;
    margin-left: 110px;
    display: block;
  }
  .navigation-bar {
    padding: 0 20px;

    .school-logo {
      padding: 13px 0px;
      align-items: center;
      img {
        height: 40px;
      }
      .text {
        h2 {
          font-size: 33.81px;
        }
        span {
          font-size: 10px;
        }
      }
    }
    .navigations {
      flex: 1;
      padding-top: 21px;
      .menu-list {
        display: flex;
        flex-direction: column;
        align-items: inherit;
        justify-content: flex-start;
        position: fixed;
        top: 71px;
        right: -340px;
        width: 250px;
        height: 100vh;
        background: #1f1f1f;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 20px;
        transition: 0.3s ease-in-out;
        z-index: 9999999;
        .menu-item {
          margin: 0 25px;
          font-size: 14px;
          font-weight: 700;
          padding: 17px 0;
          text-transform: uppercase;
          border-bottom: 1px solid #626262;
          .nav-link-item {
            color: #979797;
          }
        }
        .active {
          color: white;
          &::after {
            height: 0px;
          }
        }
      }
      .login-button{
        display: none;
      }
      .active-navbar {
        right: 0px;
      }
      .search-icon {
        display: none;
      }
    }
  }
}
