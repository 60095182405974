.why-anell{
    padding-bottom: 60px;
    .why-anell-header{
        padding: 70px 85px;
        h1{
            font-size: 47.78px;
            font-weight: 700;
            color: var(--secondary);
            width: 80%;
            line-height: 43px;
        }
        .underline{
            height: 6px;
            width: 93px;
            background-color: var(--main);
            margin-top: 24px;
        }
    }
    .anell-information{
        display: flex;
        
        .anell-information-video{
            flex: 1;
            background-image: url("../../assets/image11.png"), linear-gradient(to right,#000000B5 65%,#00000087 100%);
            background-blend-mode: overlay;
            background-size: cover;
            background-repeat: no-repeat;
            
        }
        .anell-information-details{
            flex: 3;
            .tabs{
                background-color: #F4F3F3;
                display: flex;
                width: 100%;
                font-size: 19.2px;
                .tab{
                    padding: 32px 39px 32px 47px;
                    color: #00000080;
                    transition: .4s ease;
                    &:hover{
                        background-color: white !important;
                        color: var(--main);
                        cursor: pointer;
                    }
                }
                .active{
                    background-color: white !important;
                    
                    color: var(--main);
                }
            }
            .tab-information{
                position: relative;
                min-height: 540px;
                
                .background-image{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: .05;
                    img{
                        height: 400px;
                    }
                }
                .tab-details{
                    padding: 89px 59px;
                    line-height: 21.71px;
                    font-size: 18px;
                    display: flex;
                    flex-direction: column;
                    gap: 40px;
                    text-align: justify;
                    ul{
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .extra-curriculums{
        display: flex;
        padding: 45px;
        gap: 30px;
        // background-image: url("../../assets/image10.png"), linear-gradient(to right,#000000 65%,#000000 100%);
        background-image: url("../../assets/image12.png");
        background-color: rgba(2, 29, 83,.81);
        background-blend-mode: overlay; 
        .extra-curricular{
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex: 1;
            h3{
                font-size: 16px;
                font-weight: 700;
                color: white;
            }
            p{
                color: white;
                text-align: justify;
                font-size: 13.11px;
                margin-top: 20px;
                line-height: 20.54px;
            }
        }
    }
}

// LAPTOP VIEW
@media only screen and (max-width: 1025px){
    .why-anell{
        .anell-information{
            .anell-information-details{
                .tabs{
                    .tab{
                        padding: 22px 29px 22px 24px;
                        font-size: 15px;
                    }
                }
                .tab-information{
                    .tab-details{
                        padding:71px 50px;
                        width: 100%;
                        font-size: 15px;
                    }
                }
            }
            
        }
        .extra-curriculums{
            padding: 25px;
            .extra-curricular{
                h3{
                    font-size: 12px;
                }
                p{
                    font-size: 11.11px;
                    margin-top: 10px;
                    line-height: 17px;
                }
            }
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 826px) {
    .why-anell{
        .why-anell-header{
            padding:67px 40px;
            h1{
                font-size: 38.78px;
                line-height: 12px;
            }
        }
        .anell-information{
            flex-direction: column-reverse;
            .anell-information-video{
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 400px;
                background-position: center;
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    .why-anell{
        .why-anell-header{
            padding:70px 25px;
            h1{
                font-size: 35.78px;
                width: 100%;
                line-height: initial;
            }
        }
        .anell-information{
            overflow-x: hidden;
            .anell-information-details{
                .tabs{
                    overflow-x: scroll;
                }
            }
        }
        .extra-curriculums{
            flex-direction: column;
        }
    }
}