#admission-token {
  form {
    width: 50%;
    .admission-token-form-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 50px;
      .admission-token-form-input {
        flex: 1;
        display: flex;
        flex-direction: column;
        label {
          font-weight: 600;
        }
        input[type="text"],
        select {
          padding: 8px 12px 8px 12px;
          border: 0.5px solid #00000080;
          border-radius: 6px;
          &:focus {
            outline: 0.5px solid #00000093;
          }
        }
      }
    }
    display: flex;
    flex-direction: column;
    gap: 24px;

    input[type="submit"] {
      padding: 8px 66px;
      background-color: #021d53;
      color: white;
      font-size: 16px;
      border: none;
      width: max-content;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1025px) {
  #admission-token {
    form {
      width: 80%;
    }
  }
}

@media screen and (max-width: 826px) {
  #admission-token {
    form {
      width: 90%;
    }
  }
}
@media screen and (max-width: 426px) {
  #admission-token {
    form {
      width: 100%;
      .admission-token-form-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        .admission-token-form-input {
          width: 100%;
        }
      }
    }
  }
}
