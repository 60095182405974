.banner-bar{
    height: 104px;
    background: linear-gradient(to right,#021D53 0%,#000000 90%);
    .banner-bar-text{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 30.65px;
        font-weight: 900;
        font-family: 'Geologica', sans-serif;
        gap: 25px;
        .motto-text{
            color: transparent;
            -webkit-text-stroke: .5px #FFFFFF;
            line-height: 33.71px;
            letter-spacing: 1px;
        }
        .motto{
            color: white;
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px) {
    .banner-bar{
        height: 80px;
        .banner-bar-text{
            font-size: 20px;
        }
    }
}