#admin-dashboard{
    
    
    
    .admin-dashboard-cards{
        margin-top: 35px;
        margin-bottom: 76px;
        display: flex;
        flex-wrap: wrap;
        gap: 76px;
        .admin-dashboard-card{
            flex: 1;
            background-color: #031437;
            padding: 25px;
            border-radius: 5px;
            color: white;
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-family: 'inter';
            .admin-dashboard-card-body{
                font-size: 47.81px;
                font-weight: 600;
            }
            .admin-dashboard-card-title{
                display: flex;
                gap: 10px;
                align-items: center;
                .card-title{
                    font-size: 19.2px;
                    font-weight: 600;
                }
            }
            &:nth-child(2){
                background-color: #fff;
                border: 1px solid #031437;
                color: #031437;
            }
        }
    }
}

.portal-page{
    min-height: 100vh;
    width: 80%;
    margin: auto;
    padding: 27px 0px;
    .admin-dashboard-title{
        .admin-dashboard-navigation{
            display: flex;
            align-items: center;
            gap: 5px;
            .section{
                font-size: 12px;
                color: #000000B2;
            }
            margin-bottom: 30px;
        }
        .page-title{
            font-size: 19.2px;
            font-weight: 500;
        }
    }
}

.section-list{
    .table-section-header{
        font-size: 19.2px;
        font-weight: 500;
        margin-bottom: 21px;
    }
    .section-list-table{
        overflow: auto;
        table{
            width: 100%;
            border-collapse: collapse;
            thead{
                background-color: #5BA2F433;
                border-width: 1px 0px 1px 0px;
                border-style: solid;
                border-color: #00000033;
                tr{
                    th{
                        padding: 8px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #00000080;
                        text-align: left;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        padding: 15px 10px;
                        font-size: 13.33px;
                        font-weight: 500;
                        border-bottom: 1px solid #0000001A;
                        .view-button{
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            background-color: #3699FF33;
                            color: #3699FF;
                            width: max-content;
                            padding: 10px;
                            border-radius: 6px;
                            cursor: pointer;
                        }
                        .delete-button{
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            background-color: #ff366833;
                            color: #ff3651;
                            width: max-content;
                            padding: 10px;
                            border-radius: 6px;
                            cursor: pointer;
                        }
                    }
                    .action-buttons{
                        display: flex;
                        gap: 10px;
                    }
                }
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1026px){
    #admin-dashboard{
        width: 95%;
    }
}

// TABLET VIEW
@media screen and (max-width: 826px){
    #admin-dashboard{
        .admin-dashboard-cards{
            gap: 10px;
        }
    }
}

