#contact{
    .contact-details{
        display: flex;
        padding: 99px 125px;
        background: linear-gradient(90deg, #000000, #021D53);
        .contacts{
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: white;
            .contact{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .contact-icon{
                    svg{
                        font-size: 64px;
                    }
                }
                .contact-info{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .contact-name{
                        font-size: 33.18px;
                        font-weight: 700;
                    }
                    .contact-description{
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .contact-form-container{
        padding: 120px;
        width: 70%;
        margin: auto;
        .contact-form-header{
            text-align: center;
            margin-bottom: 21px;
            h3{
                font-size: 39.81px;
                font-weight: 800;
                margin-bottom: 10px;
                color: #021D53;
            }
            p{
                color: #010D25;
            }
        }
        .contact-form{
            
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding: 48px 20px;
            background-color: #C9DBFF;
            border: 1px solid #C9DBFF;
            input{
                border: none;
                height: 56px;
                padding: 24px 19px;
                font-size: 13.33px;
                font-weight: 500;
                color: #021D53;
                &:focus{
                    outline: 1px solid #021D53;
                }
                &::placeholder{
                    font-size: 13.33px;
                    font-weight: 500;
                    color: #021D53;
                }
            }
            textarea{
                border: none;
                font-weight: 500;
                padding: 24px 19px;
                font-size: 13.33px;
                color: #021D53;
                &:focus{
                    outline: 1px solid #021D53;
                }
                &::placeholder{
                    font-weight: 500;
                    font-size: 13.33px;
                    color: #021D53;
                }
            }
            input[type="submit"]{
                background-color: #021D53;
                color: white !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px;
                font-size: 16px;
                font-weight: 500;
                &:hover{
                    background-color: #022367;
                    cursor: pointer;
                }
            }
        }
    }
    .contact-map-direction{
        .map-direction-header{
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            margin-bottom:60px;
            h1{
                color: #021D53;
                font-size: 47.78px;
            }
            .underline{
                width: 93px;
                height: 6px;
                background-color: #00000080;
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #contact{
        .contact-details{
            padding: 65px 35px;
            .contacts{
                .contact{
                    gap: 10px;
                    .contact-info{
                        gap: 10px;
                    }
                }
            }
        }
        .contact-form-container{
            width: 90%;
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    #contact{
        .contact-details{
            padding:35px 25px;
            .contacts{
                .contact{
                    gap: 4px;
                    .contact-icon{
                        svg{
                            font-size: 39px;
                        }
                    }
                    .contact-info{
                        gap: 4px;
                        .contact-name{
                            font-size: 23.18px;
                        }
                        .contact-description{
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .contact-form-container{
            width: 100%;
            .contact-form-header{
                h3{
                    font-size: 29.81px;
                }
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px) {
    #contact{
        .contact-details{
            .contacts{
                flex-direction: column;
                gap: 15px;
                .contact{
                    flex-direction: row;
                    align-items: center;
                    gap:15px;
                }
            }
        }
        .contact-form-container{
            padding: 25px 15px;
            .contact-form-header{
                h3{
                    font-size: 22.81px;
                }
            }
        }
    }
}