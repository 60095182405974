
#message-container{
    #message{
        // width: 100%;
        // height: 580px;
        border-radius: 16px;
        -webkit-box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.12);
        -moz-box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.12);
        box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.12);
        padding: 15px 10px;
        display: flex;
        align-items: flex-start;
        gap: 30px;
        position: relative;
        transition: all .5s ease-in-out;
        margin-bottom: 40px;
       
        
        .close-btn{
            position: absolute;
            top: 23px;
            right: 23px;
            cursor: pointer;
        }
        .image-section{
            display: flex;
            width: 370px;
            border-radius: 16px 0px 0px 16px;
            // background-image: url("../../../assets/aboutpage/LeadersMessage/messageImage.png");
            align-items: flex-end;
            justify-content: center;
            // padding-bottom: 40px;
            background-repeat: no-repeat;
            border-radius: 16px;
            background-position: center;
            background-size: cover;
            position: relative;
            
            .image-section-footer{
                display: flex;
                align-items: flex-end;
                gap: 40px;
                position: absolute;
                bottom: 20px;
                .leader-name-position{
                    width: 175px;
                    color: white;
                    .name{
                        font-size: 19.2px;
                        font-weight: 700;
                    }
                    .position{
                        letter-spacing: 2px;
                        font-weight: 400;
                        text-transform: uppercase;
                    }
                }
                .read-message-button{
                    font-size: 14.26px;
                    border: 1px solid white;
                    color: white;
                    font-weight: 200;
                    padding: 6px;
                    border-radius: 18px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;
                }
            }
        }
        .leader-message-section{
            width: 999px;
            font-size: 15px;
            text-align: justify;
            padding: 10px 0px;
            color: #00000099;
            transition: all .5s ease-in-out;
            p{
                font-weight: 600;
                margin-bottom: 15px;
                line-height: 17.25px;
            }
            ul{
                list-style-type: none;
                margin-bottom: 15px;
            }
            h3{
                color: black;
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #message-container{
        .message-section-header{
            margin-bottom: 20px!important;
            h2{
                width: 50%!important;
            }
        }
        #message{
            .leader-message-section{
                font-size: 12px;
            }
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    #message-container{
        .message-section-header{
            h2{
                width: 100%!important;
            }
        }
        #message{
            flex-direction: column;
            align-items: center;
            svg{
                display: none!important;
            }
            .leader-message-section{
                width: auto!important;
                // display: none!important;
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width:426px){
    #message-container{
        .message-section-header{
            h2{
                width: 100%!important;
            }
        }
        #message{
            width: auto;
            flex-direction: column;
            svg{
                display: none!important;
            }
            .image-section{
                img{
                    height: 380px;
                    margin-top: 40px;
                }
                .image-section-footer{
                    
                }
            }
            .leader-message-section{
                width: auto;
            }
        }
    }
}