#medicals{
    padding: 23px 50px;
    border-top: 1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    gap: 50px;
    font-weight: 600;
    p{
        width: 70%;
        &:nth-child(2){
            font-weight: 400;
            font-style: italic;
        }
    }
    .medicals-bottom{
        input[type=email]{
            padding: 5px;
            width: 50%;
        }
        .purchase-button{
            margin-top: 5px;
            padding: 12px;
            width: max-content;
            background-color: #021D53;
            border-radius: 6px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: .3s ease-in-out;
            &:hover{
                background-color: #021d53eb;
            }
        }
    }
}