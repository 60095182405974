.calendar-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    .calendar-header{
        font-size: 39.81px;
        font-weight: 700;
        color: #021D53;
    }
    .calendar-accordions{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}