#calendar-accordion{
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    border-radius: 16px;
    margin-bottom: 16px;
    width: 100%;
    transition:all .4s ease;
}
.calendar-accordion-item-open{
    background-color: white;
    border: 1px solid #0000004D;
    .calendar-accordion-item-title{
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        margin-bottom:24px;
        font-size: 23.04px;
        gap: 5px;
        cursor:pointer;
        p{
            color: #021D53;
            width: 95%;
        }
        svg{
            font-size: 20px;
            width: 5%;
        }
    }
    .calendar-accordion-details{
        .activities-title{
            letter-spacing: 5px;
            text-transform: uppercase;
            position: relative;
            color: #021D53;
            font-weight: 600;
            margin-bottom: 10px;    
        }
        .activities-list{
            width: 100%;
            border-collapse: collapse;
            .activity{
                td{
                    padding:10px 20px;
                    border: 1px solid #eee;
                }
                .activity-name{
                    width: 50%;
                    color: #021D53;
                    font-style: italic;
                }
                .activity-date{
                    font-weight: 700;
                }
            }
            .important-event{
                background-color: #32508d57;
            }
        }
        .calendar-bottom{
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #021D53;
            color: white;
            font-size: 30px;
        }
    }
}
.calendar-accordion-item-close{
    background-color: #021D53;
    border: 1px solid #5BA2F433;
    color: white;
    .calendar-accordion-item-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        gap: 20px;
        font-size: 23.04px;
        cursor:pointer;
        
        svg{
            font-size: 20px;
            
        }
    }
    .calendar-accordion-details{
        display: none;
        
    }
}

// MOBILE VIEW
@media screen and (max-width: 1025px){
    .calendar-accordion-item-open{
        .calendar-accordion-item-title{
            font-size: 1em;
        }
    }

    .calendar-accordion-item-close{
        .calendar-accordion-item-title{
            font-size: 1em;
        }
    }
}