#about-anell-container{
    .about-anell{
        padding: 100px 171px;
        .header{
            margin-bottom: 30px;
            h1{
                margin-bottom: 10px;
                font-size: 39.81px;
                color: var(--secondary);
            }
            .underline{
                width: 157px;
                height: 2px;
                background-color:var(--faded-black)
            }
        }
        .about-content{
            line-height: 23.4px;
            font-size: 19.2px;
            font-weight: 500;
            .first-text{
                p{
                    color: #021D53;
                    margin-bottom: 20px;
                    text-align: justify;
                }
            }
            .school-about-section{
                margin-bottom: 13px;
                .section-title{
                    color: #01256d;
                    font-size: 18px;
                }
                .section-content{
                    font-size: 16px;
                }

            }
            .second-text{
                font-size:16px;
                p{
                    margin-bottom: 20px;
                }
            }
        }
    }
    .about-mission-vision{
        display: flex;
        .mission{
            width: 50%;
            background-color: #221B2DE5;
            padding: 50px 94px 50px 171px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .mission-header{
                color: transparent;
                font-family: 'Geologica', sans-serif;
                font-size: 27.65px;
                font-weight: 900;
                text-transform: uppercase;
                -webkit-text-stroke: 1px white;
                
            }
            .mission-text{
                color: white;
                font-weight: 500;
            }
        }
        .vision{
            width: 50%;
            padding: 50px 171px 50px 50px;
            background-color: #dcdcdce5;
            display: flex;
            flex-direction: column;
            gap: 20px;
            
            .vision-header{
                color: transparent;
                font-family: 'Geologica', sans-serif;
                font-size: 27.65px;
                font-weight: 900;
                text-transform: uppercase;
                -webkit-text-stroke: 1px #000000;
                
            }
            .vision-text{
                p{
                    margin-bottom: 15px;
                }
            }

        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #about-anell-container{
        .about-anell{
            padding: 80px 98px;
            .about-content{
                font-size: 14.2px;
                .second-text{
                    font-size: 13px;
                }
            }
        }
        .about-mission-vision{
            .mission{
                padding: 40px 20px 40px 98px;
                .mission-text{
                    font-size: 12px;
                }
            }
            .vision{
                padding: 40px 98px 40px 30px;
                .vision-text{
                    font-size: 12px;
                }
            }
        }
    }
}

// TABLET VIEW
@media screen and (max-width:769px){
    #about-anell-container{
        .about-anell{
            padding: 45px 38px;
        }
        .about-mission-vision{
            .mission{
                padding: 40px 20px 40px 37px;
            }
            .vision{
                padding: 40px 37px 40px 30px;
            }
        }
    }
}


// MOBILE VIEW
@media screen and (max-width:426px){
    #about-anell-container{
        .about-mission-vision{
            flex-direction: column;
            .mission{
                width: 100%;
            }
            .vision{
                width: 100%;
            }
        }
    }
}







