.dashboard-menu-item{
    color: #FFFFFF80;
    padding: 8px;
    transition: .4s ease-in-out;
    position: relative;
    transition: all 0.3s ease-in-out;
    .dashboard-nav-link-item{
        color: #FFFFFF80;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
    }
    .active{
        color: white;
    }
    &:hover{
        background-color: #1E2027;
        border-radius: 5px;
        cursor: pointer;
    }
    .dashboard-submenu{
        position: absolute;
        top: 57px;
        left: 0px;
        list-style: none;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px 14px;
        width: 212px;
        background: linear-gradient(#021D53, #011742);
        .dashboard-submenu-item{
            padding: 5px;
            .dash-sub-nav-link-item{
                color: #fff;
                &:hover{
                    color: #a1c0ff;
                }
            }
        }
    }
    .open-dashboard-submenu{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: .3s ease-in-out;
    }
    .close-dashboard-submenu{
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: .3s ease-in-out;
    }
}

// MOBILE VIEW
@media screen and (max-width: 826px){
    .dashboard-menu-item{
        .dashboard-nav-link-item{
            justify-content: space-between;
        }
    }
}