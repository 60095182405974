.enquiry{
    .enquiry-details{
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        .field-data{
            width: 30%;
            label{
                font-size: 15px;
                color: #8A8A8A;
            }
            p{
                font-size: 20px;
            }
        }
    }
    .enquiry-buttons{
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 55px;
        .enquiry-button{
            padding:10px 20px;
            border: none;
            font-size: 20px;
            min-width: 150px;
        }
        .button-approve{
            color: white;
            background-color: #6A9C89;
            border: 1px solid #6A9C89;
            transition: .4s ease-in-out;
            &:hover{
                cursor: pointer;
                background: transparent;
                
                color: #6A9C89;
            }
        }
        .button-reject{
            color: white;
            background-color: #D83F31;
            border: 1px solid #D83F31;
            transition: .4s ease-in-out;
            &:hover{
                cursor: pointer;
                background: transparent;
                
                color: #D83F31;
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    .enquiry{
        .enquiry-details{
            .field-data{
                width: 46%;
            }
        }
    }
}