.footer{
    // height:358px;
    background-image: url("../../assets/image14.png");
    background-color: #021d53c1;
    background-blend-mode: overlay; 
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .footer-info{
        display: flex;
        gap: 150px;
        padding: 40px;
        .first-section{
            .school-logo{
                display: flex;
                gap: 20px;
                align-items: center;
                img{
                    height: 124px;
                }
                .text{
                    
                    h2{
                        font-size: 68.8px;
                        font-weight: 700;
                        color: white;
                        line-height: 55.87px;
                    }
                    p{
                        font-size: 13.33px;
                        font-weight: 400;
                        color: white;
                    }
                    .underline{
                        margin-top: 4px;
                        height: 1px;
                        width: 195px;
                        background-color: var(--white);
                    }
                }
            }
            .donate-button{
                background-color: #fff;
                width: max-content;
                float: right;
                padding: 16px 66px;
                border: 1px solid #fff;
                cursor: pointer;
                transition: .3s ease-in-out;
                &:hover{
                    background: transparent;
                    color: white;
                }
            }
        }
        .footer-sections{
            display: flex;
            gap: 150px;
            .footer-section{
                margin-top: 20px;
                .header{
                    h3{
                        font-size: 27.65px;
                        font-size: 400;
                        color: white;
                    }
                    .underline{
                        margin-top: 4px;
                        height: 1px;
                        width: 195px;
                        background-color: var(--white);
                    }
                }
                .list{
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    color: white;
                    font-weight: 400;
                    font-size: 13.33px;
                    a{
                        color: white;
                    }
                }
            }
        }
        
    }
    .copyright-info{
        padding: 22px 200px;
        width: 100%;
        background-color: #021d53c1;
        .content{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
                color: white;
            }
            .social-media{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                .social-media-icon{
                    padding: 6px 6px 3px;
                    background-color: var(--main);
                    border: 1px solid var(--main);
                    border-radius: 8px;
                    transition: all .2s ease-in-out;
                    svg{
                        color: white;
                        font-size: 20px;
                    }
                    &:hover{
                        background-color: transparent;
                        border: 1px solid white;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    .footer{
        .footer-info{
            gap: 45px;
        }
        .copyright-info{
            padding: 22px 101px;
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    .footer{
        .footer-info{
            padding: 22px;
            .school-logo{
                img{
                    height: 80px;
                }
                .text{
                    h2{
                        font-size: 45.8px;
                    }
                    p{
                        font-size: 9.33px;
                    }
                    .underline{
                        width: 123px;
                    }
                }
            }
            .footer-sections{
                gap: 45px;
                .footer-section{
                    margin-top: 43px;
                    .header{
                        h3{
                            font-size: 22.65px;
                        }
                        .underline{
                            width: 129px;
                        }
                    }
                }
            }

        }
        .copyright-info{
            padding: 18px 101px;
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    .footer{
        .footer-info{
            flex-direction: column;
            gap: 0px;
            .footer-sections{
                .footer-section{
                    margin-top: 26px;
                }
            }
        }
        .copyright-info{
            padding: 10px 20px;
            .content{
                p{
                    font-size: 10px;
                }
                .social-media{
                    gap: 10px;
                    .social-media-icon{
                        svg{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}