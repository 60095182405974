
/* ----------------------------------------- *\
            # FONTS
\* ----------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



/* ----------------------------------------- *\
            #CUSTOM PROPERTIES
\* ----------------------------------------- */



:root{

    /* COLORS */
    --primary:#ED3137;
    --main:#221B2D;
    --secondary: #021D53;
    --light-blue:#C9DBFF;
    --dark-main: #040404;
    --black: #110E0E;
    --white: #FFFFFF;
    --faded-black: #2b2b2b;
    --gray:#00000080;
}



/* ----------------------------------------- *\
            #RESET
\* ----------------------------------------- */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a{
    text-decoration: none;
    color: inherit;
}

body{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: var(--black);
    background-color: #FFFFFF;
    font-size: 16px;
    overflow: auto !important;
}

/* ----------------------------------------- *\
            #REUSED STYLE
\* ----------------------------------------- */

.min-w-50{
    min-width: 50% !important;
}

.text-underline{
    text-decoration: underline;
}

.d-flex{
    display: flex;
}

.items-center{
    align-items: center;
}

.classic-button{
    background-color: white;
    width: max-content;
    color: black;
    font-weight: 600;
    padding:15px 50px;
    border-left: 5px solid var(--main);
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: all .4s ease;
    
}

.classic-button:hover{
    background: transparent;
    border-left: 5px solid transparent;
    border: 2px solid white;
    color: white;
    cursor: pointer;
}


.main-container{
    padding: 70px 161px;
}


.required{
    color: red;
}

.cursor-pointer{
    cursor: pointer;
}

.custom-tabs{
    display: flex;
    
    margin-top: 20px;
    
}

.custom-tab-section{
    padding: 10px 15px;
    border-left: 1px solid #787878;
    cursor: pointer;
}


.custom-tab-section.active{
    background-color: #021D53;
    color: white;
}

.filter-group{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
}

.form-item{
    padding: 10px;
    min-width: 200px;
}

.not-found{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.not-found h3{
    font-size: 40px;
    color: #021D53;
}

.two-columns{
    display: flex;
    gap: 20px;
}



/* ----------------------------------------- *\
            #RESPONSIVENESS
\* ----------------------------------------- */

/* LAPTOP VIEW */
@media screen and (max-width: 1025px){
    .main-container{
        padding: 70px 37px;
    }
    .filter-group{
        flex-direction: column;
    }
}

/* TABLET VIEW */
@media only screen and (max-width: 769px){
    .classic-button{
        padding: 9px 25px;
    }
}

/* MOBILE VIEW */
@media screen and (max-width: 426px){
    .classic-button{
        padding: 10px 30px;
    }
    .main-container{
        padding: 50px 0;
        max-width: 90%;
        margin: auto;
    }
    .two-columns{
        flex-direction: column;
        gap: 40px;
    }
}