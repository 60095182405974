#news-item-section{
    width: 80%;
    margin: auto;
    padding: 58px 0px;
    display: flex;
    .main-news{
        width: 79.125%;
        padding-right: 5.5%;
        border-right: 1px solid #666;
        .news-item-title{
            font-size: 30px;
            color: #333;
            padding-bottom: 10px;
            line-height: 1em;
            font-weight: 700;
        }
        .news-item-description{
            font-size: 14px;
            padding-bottom: 15px;
            color: #666;
            
        }
        .news-item-main-image{
            max-width: 100%;
            img{
                max-width: 100%;
            }
        }
        .news-item-body{
            word-wrap: break-word;
            text-align: justify;
            color: #666;
            line-height: 1.4em;
            font-weight: 400;
            padding: 20px 0px;
        }
        .event-images{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            .event-image{
                width: 32%;
                height: 196px;
                overflow: hidden;
                img{
                    object-fit: cover;
                    max-width: 100%;
                }
            }
        }
    }
    .recent-posts{
        padding-left: 30px;
        width: 20.875%;
        padding-bottom: 28px;
        .recent-posts-title{
            width: 100%;
            font-size: 26px;
            color: #333;
            padding-bottom: 10px;
            line-height: 1em;
        }
        .recent-posts-list{
            list-style-type: none;
            .recent-post{
                margin-bottom: 0.5em;
                font-size: 13px;
                font-weight: 300;
                a{
                    color: #666;
                    &:hover{
                        cursor: pointer;
                        color: var(--secondary);
                    }
                }
            }
        }
    }
}


// LAPTOP VIEW
@media screen and (max-width: 1025px){
    #news-item-section{
        .main-news{
            .event-images{
                .event-image{
                    height: 130px;
                }
            }
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 769px){
    #news-item-section{
        flex-direction: column;
        .main-news{
            width: auto;
            border: none;
        }
        .recent-posts{
            width: auto;
            padding-top:30px ;
            padding-left: 0px;
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    #news-item-section{
        
        .main-news{
            .event-images{
                .event-image{
                    height: 100px;
                    width: 48%;
                }
            }
        }
        
    }
}