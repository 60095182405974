#careers-section {
  .careers {
    .careers-intro {
      padding: 0 161px;
      color: #021d53;
      text-align: justify;
      margin-bottom: 60px;
      p {
        margin-bottom: 15px;
        font-size: 19.2px;
        font-weight: 600;
      }
    }
    .career-objective {
      padding: 0 161px;
      color: #021d53;
      .career-objective-header {
        text-transform: uppercase;
        font-size: 19.2px;
        margin-bottom: 20px;
      }
      .career-objectives {
        margin-left: 20px;
        .career-objective-item {
          margin-bottom: 5px;
        }
      }
    }
    .career-items {
      padding: 0 161px;
      margin-top: 40px;
      display: flex;
      gap: 20px;
      margin-bottom: 40px;
    }
    .career-description {
      background-color: #f0f5ff;
      padding: 41px 161px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .join-our-team-section {
      // padding: 60px 161px;
      .application-description {
        border: 1px solid #0000004d;
        border-radius: 20px;
        background-color: #fbfcff;
        color: #000000b2;
        padding: 32px;
        .application-description-header {
          color: #000 !important;
          margin-bottom: 20px;
        }
        .application-header-text {
          width: 73%;
          margin-bottom: 25px;
        }
        a {
          color: #021d53;
          text-decoration: underline;
        }
        .application-requirements {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          font-size: 16px;
          line-height: 19.5px;
          font-weight: 600;
          margin-bottom: 30px;
        }
        .additional-application-info {
          display: flex;
          flex-direction: column;
          gap: 30px;
          .school-address {
            list-style-type: none;
            line-height: 16px;
          }
          .additional-requirements {
            h3 {
              margin-bottom: 10px;
            }
            .requirement-list {
              margin-left: 20px;
              display: flex;
              flex-direction: column;
              gap: 5px;
            }
          }
        }
      }
    }
  }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px) {
  #careers-section {
    .careers {
      .careers-intro {
        padding: 0 37px;
      }
      .career-objective {
        padding: 0 37px;
      }

      .career-items {
        padding: 0 37px;
      }
      .career-description {
        padding: 41px 37px;
      }
    }
  }
}

// TABLET VIEW
@media screen and (max-width: 769px) {
  #careers-section {
    .careers {
      .careers-intro {
        p {
          font-size: 15px;
        }
      }
      .career-objective {
        padding: 0 37px;
      }

      .career-items {
        padding: 0 37px;
      }
      .career-description {
        padding: 41px 37px;
      }
    }
  }
}

// MOBILE VIEW
@media screen and (max-width: 426px) {
  #careers-section {
    .careers {
      .careers-intro {
        p {
          font-size: 15px;
        }
      }
      .career-objective {
        padding: 0 37px;
      }

      .career-items {
        flex-direction: column;
      }
      .career-description {
        padding: 41px 37px;
      }
      .join-our-team-section{
        .application-description{
            padding: 20px;
            .application-header-text{
                width: 100%;
                font-size: 14px;
            }
            .application-requirements{
                font-size: 14px;
            }
            .additional-application-info{
                font-size: 14px;
            }
        }
      }
    }
  }
}
