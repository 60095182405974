.admissions{
    position: relative;
    height: max-content;
    .admissions-card{
        background-color: white;
        width: 95%;
        margin: auto;
        // position: absolute;
        // top: -5%;
        // left: 50%;
        transform: translateY(-20px);
        border-radius: 10px 10px 0px 0px;
        -webkit-box-shadow: -5px 40px 74px -38px rgba(0,0,0,0.51);
        -moz-box-shadow: -5px 40px 74px -38px rgba(0,0,0,0.51);
        box-shadow: -5px 40px 74px -38px rgba(0,0,0,0.51);
        .activities{
            display: flex;
            justify-content: space-between;
            padding:60px 80px;
            .activity{
                display: flex;
                gap: 15px;
                svg{
                    font-size: 40.83px;
                    color: #00000033;
                }
                .description{
                    h3{
                        font-size: 16.5px;
                        font-weight: 700;
                        color: var(--secondary);
                    }
                    p{
                        color: #00000040;
                        font-weight: 500;
                    }
                }
            }
        }
        .admission-statement{
            display: flex;
            
            .left{
                flex: 1;
                .image{
                    background-image: url("../../assets/admission.png");
                    background-size: cover;
                    // background-position:center ;
                    height: 100%;
                }
                
            }
            .right{
                flex: 1;
                background-color: #021D53;
                display: flex;
                justify-content: center;
                .admission-information{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 20px;
                    margin: auto;
                    padding:130px 50px;
                    h2{
                        color: white;
                        font-size: 39.81px;
                        font-weight: 900;
    
                    }
                    h4{
                        font-size: 23.04px;
                        font-weight: 500;
                        color: var(--white);
                        width: 90%;
                    }
                    p{
                        font-size: 16px;
                        color: white;
                        font-weight: 400;
                        text-align: justify;
                        margin-top: 30px;
                        margin-bottom: 50px;
                    }
                }
            };
        }
    }
}



// LAPTOP VIEW
@media only screen and (max-width: 1026px) {
    .admissions{
        .admissions-card{
            width: 95%;
        
            .activities{
                padding: 60px 30px;
            }
            .admission-statement{
                .right{
                    .admission-information{
                        h2{
                            font-size:30px;
                        }
                        h4{
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

// TABLET VIEW
@media only screen and (max-width: 769px) {
    .admissions{
        .admissions-card{
            .activities{
                padding: 37px 30px;
                .activity{
                    svg{
                        font-size: 25.83px;
                    }
                    .description{
                        h3{
                            font-size: 11px;
                        }
                        p{
                            font-size: 10px;
                        }
                    }
                }
            }
            .admission-statement{
                .right{
                    .admission-information{
                        h2{
                            font-size:23px;
                        }
                        h4, p{
                            font-size: 13px;
                        }
                        
                    }
                }
            }
        }
    }
}

// MOBILE VIEW
@media only screen and (max-width: 426px){
    .admissions{
        
        .admissions-card{
            width: 100%;
            transform: translateY(0px);    
            .activities{
                flex-direction: column;
                gap: 37px;
                padding:57px 37px;
                .activity{
                    
                    align-items: center;
                    svg{
                        font-size: 53.83px;
                    }
                    .description{
                        h3{
                            font-size: 25px;
                            
                        }
                        p{
                            font-size: 20px;
                        }
                    }
                }
            }
            .admission-statement{
                flex-direction: column;
                .left{
                    flex: 1;
                    
                    height: 100%;
                    img{
                        width: -webkit-fill-available;
                        height: auto;
                        object-fit: cover;
                    }
                }
                .right{
                    padding:0px;
                    .admission-information{
                        width: 100%;
                        h2{
                            
                            font-size: 23px;
                            font-weight: 700;
                        }
                        h4{
                            font-size: 15px;
                        }
                        
                    }
                };
            }
        }
    }
}