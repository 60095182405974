.map-direction{
    .map-direction-header{
        padding: 70px 85px 40px;
        h1{
            font-size: 47.78px;
            font-weight: 700;
            color: var(--secondary);
            width: 80%;
            line-height: 43px;
        }
        .underline{
            height: 6px;
            width: 93px;
            background-color: var(--main);
            margin-top: 24px;
        }
    }
    
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    .map-direction{
        .map-direction-header{
            padding: 70px 25px 40px;
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    .map-direction{
        .map-direction-header{
            h1{
                font-size: 40px;
            }
            .underline{
                margin-top: 6px;
            }
        }
    }
}