.about-section{
    background-image: url("../../assets/about.png"), linear-gradient(to right,#221B2D9E 65%,#221B2D9E 100%);
    background-blend-mode: overlay;
    background-size: cover;
    background-position-y: bottom;
    padding: 70px 85px;
    display: flex;
    
    .about-left{
        flex: 3;
        h1{
            font-family: 'Geologica', sans-serif;
            color: transparent;
            -webkit-text-stroke: .5px #FFFFFF;
            font-size: 55px;
            font-weight: 700;
        }
        .school-logo{
            flex: 3;
            padding: 10px 0px;
            display: flex;
            gap: 10px;
            img{
                height: 100px;
            }
            .text{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
                .main-text{
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    h2{
                        font-size: 49.81px;
                        font-weight: 700;
                    }
                    span{
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        font-weight: 400;
                    }
                }
                .motto{
                    font-style: italic;
                    color: var(--white);
                    font-weight: 600;
                }
            }
        }
    }
    .about-right{
        flex: 4;
        display: flex;
        flex-direction: column;
        gap: 60px;
        .about-school{
            text-align: justify;
            color: white;
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 95;
        }
    }
}

// LAPTOP VIEW
@media only screen and (max-width: 1025px){
    .about-section{
        padding: 44px 27px;
        background-position: left;
        .about-right{
            gap: 40px;
            .about-school{
                p{
                    font-size: 13px;
                }
            }
        }
    }
}

// TABLET VIEW
@media only screen and (max-width: 835px){
    .about-section{
        background-position-x: -209px;
        .about-left{
            h1{
                font-size: 48.81px;
            }
            .school-logo{
                img{
                    height: 70px;
                }
                .text{
                    .main-text{
                        h2{
                            font-size: 31.81px;
                        }
                        span{
                            font-size: 12px;
                        }
                    }
                    .motto{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

// MOBILE VIEW
@media only screen and (max-width: 768px){
    .about-section{
        flex-direction: column;
        background-position-x: center;
    }
}