.news-events{
    padding: 70px 85px;
    
    .news-events-header{
        h1{
            font-size: 47.78px;
            font-weight: 700;
            color: var(--secondary);
            width: 80%;
            line-height: 43px;
        }
        .bottom{
            display: flex;
            align-items: center;
            margin-top: 16px;
            gap: 10px;
            .read-all-news{
                color: var(--gray   );
                font-size: 19.2px;
                &:hover{
                    cursor: pointer;
                    color:#021d53c1;
                }
            }
            .underline{
                height: 6px;                    
                width: 93px;
                background-color: var(--secondary);
                
            }
        }
        
    }
    .content{
        margin-top: 40px;
        display: flex;
        padding: 20px 0;
        align-items: flex-start;
        .news{
            flex: 1;
            width: 33%;
            .news-content{
                width: 68%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                img{
                    max-width: 100%;
                    max-height: 80%;
                }
                p{
                    color: #8e8e8e;
                    font-size: 14px;
                    text-align: left;
                }
                .news-caption{
                    font-size: 20.04px;
                    font-weight: 500;
                    text-align: justify;
                    color: black;
                    
                    &:hover{
                        color:#021d53c1;
                        cursor: pointer;
                    }
                }
            }
        }
        .events{
            flex: 1;
            .events-content{
                .title{
                    display: flex;
                    align-items: flex-end;
                    gap: 15px;
                    h3{
                        font-size: 23.04px;
                        font-weight: 700;
                    }
                    .bar{
                        height: 6px;
                        width: 93px;
                        background-color: var(--main);
                        margin-bottom: 7px;
                    }
                }
                .event-list{
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    .event-item{
                        display: flex;
                        gap: 20px;
                        width: 75%;
                        .date{
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            .day{
                                font-size: 40px;
                                font-weight: 700;
                                color: var(--secondary);
                                margin-left: -2px;
                            }
                            .month{
                                font-size: 16px;
                                color: #110E0E80;
                                font-weight: 700;
                                text-transform: uppercase;
                            }
                            .date-underline{
                                margin-top: 5px;
                                height: 3px;
                                width: 33px;
                                background-color: var(--main);
                            }
                        }
                        .event-details{
                            flex: 4;
                            margin-top: 7px;
                            .event-title{
                                h3{
                                    font-weight: 700;
                                    font-size: 15px;
                                    color: var(--secondary);
                                }
                            }
                            .time-venue{
                                margin-top: 15px;
                                display: flex;
                                justify-content: space-between;
                                font-size: 13.11px;
                                font-weight: 600;
                                .time{
                                    display: flex;
                                    align-items: center;
                                    gap: 5px;
                                    svg{
                                        color: var(--main);
                                    }
                                }
                                .venue{
                                    svg{
                                        margin-right: 5px;
                                        color: var(--main);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .admission-apply{
            flex: 1;
            // height: 488px;
            background-image: url("../../assets/image13.png");
            background-color: #021d53c1;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-blend-mode: overlay;
            // position: relative;
            .admission-content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 50px;
                // position: absolute;
                // top: 0;
                // left: 0;
                // transform: translate(16%, 57%);
                padding:70px;
                .apply-title{
                    font-size: 47.78px;
                    font-weight: 700;
                    color: white;
                }
            }
        }
    }
}

// LAPTOP VIEW
@media screen and (max-width: 1025px){
    .news-events{
        padding: 20px 26px;
        .content{
            flex-direction: column;
            .news{
                .news-content{
                    width: 75%;
                    p{
                        font-size: 12px;
                    }
                    h3{
                        font-size: 16.04px;
                    }
                }
            }
            .events{
                .events-content{
                    .title{
                        gap: 10px;
                        h3{
                            font-size: 17.04px;
                        }
                        .bar{
                            height: 3px;
                            width: 68px;
                            margin-bottom: 4px;
                        }
                    }
                    .event-list{
                        margin-top: 12px;
                        .event-item{
                            .date{
                                .day{
                                    font-size: 33px;
    
                                }
                                .month{
                                    font-size: 13px;
                                }
                            }
                            .event-details{
                                .event-title{
                                    h3{
                                        font-size: 13px;
                                    }
                                }
                                .time-venue{
                                    margin-top:12px;
                                    .time{
                                        font-size: 11px;
                                    }
                                    .venue{
                                        font-size: 11px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .admission-apply{
                .admission-content{
                    padding: 100px 70px;
                    .apply-title{
                        font-size: 35.78px;
                    }
                }
            }
        }
    }
}

// TABLET VIEW
@media screen and (max-width: 835px){
    .news-events{
        .content{
            
            .news{
                .news-content{
                    gap: 16px;
                    p{
                        font-size: 10px;
                    }
                    h3{
                        font-size: 13.04px;
                    }
                }
            }
            .events{
                .events-content{
                    .title{
                        h3{
                            font-size: 13.04px;
                        }
                        .bar{
                            height: 2px;
                            width: 45px;
                        }
                    }
                    .event-list{
                        gap: 20px;
                        .event-item{
                            gap: 5px;
                            .date{
                                .day{
                                    font-size: 25px;
                                }
                                .month{
                                    font-size: 10px;
                                }
                                .date-underline{
                                    margin-top: 2px;
                                    height: 1px;
                                    width: 25px;
                                }
                            }
                            .event-details{
                                margin-top: 4px;
                                .event-title{
                                    h3{
                                        font-size: 10px;
                                    }
                                }
                                .time-venue{
                                    margin-top: 6px;
                                    .time{
                                        font-size: 9px;
                                    }
                                    .venue{
                                        font-size: 9px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .admission-apply{
                .admission-content{
                    padding: 43px 25px;
                    .apply-title{
                        font-size: 30px;
                    }
                }
            }
        }
    }
}

// MOBILE VIEW
@media screen and (max-width: 426px){
    .news-events{
        .news-events-header{
            h1{
                font-size: 37.78px;
            }
            .bottom{
                margin-top: 2px;
            }
        }
        .content{
            flex-direction: column;
            gap: 35px;
            .news{
                .news-content{
                    width: 100%;
                    p{
                        font-size: 18px;
                    }
                    h3{
                        font-size: 22.04px;
                    }
                }
            }
            .events{
                .events-content{
                    .title{
                        h3{
                            font-size: 25.04px;
                        }
                        .bar{
                            height: 3px;
                            width: 60px;
                            margin-bottom: 6px;
                        }
                    }
                    .event-list{
                        gap: 30px;
                        .event-item{
                            width: 90%;
                            .date{
                                .day{
                                    font-size: 35px;
                                }
                                .month{
                                    font-size: 15px;
                                }
                                .date-underline{
                                    margin-top: 2px;
                                    height: 2px;
                                    width: 34px;
                                }
                            }
                            .event-details{
                                .event-title{
                                    h3{
                                        font-size: 16px;
                                    }
                                }
                                .time-venue{
                                    .time, .venue{
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }

                }
            }
            .admission-apply{
                .admission-content{
                    gap: 30px;
                    padding: 43px 75px;
                    .apply-title{
                        font-size: 36px;
                    }
                }
            }
        }
    }
}