#banner-container{
    .banner{
        // background-image: url("../../assets/aboutpage/aboutimage.png"),linear-gradient(to right,#0000007b 65%,#0000007d 100%);
        background-blend-mode: overlay;
        height: 441px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .banner-text{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            gap: 20px;
            color: white;
            h1{
                font-size: 47.78px;
                font-weight: 700;
                letter-spacing: 2px;
            }
            .bottom{
                display: flex;
                align-items: center;
                gap: 3px;
                svg{
                    color: #FFFFFF66;
                }
            }
            .underline-bottom{
                width: 157px;
                height: 1px;
                background-color: #fff;
            }
        }
    }
    
}


// TABLET VIEW
@media screen and (max-width: 769px){
    #banner-container{
        .banner{
            background-size: contain;
            height: auto;
            .banner-text{
                padding: 70px;
            }
        }
    }
}


// MOBILE VIEW
@media screen and (max-width: 426px){
    #banner-container{
        
        .banner{
            .banner-text{
                padding: 40px;
                gap: 4px;
                h1{
                    font-size: 24.78px;
                }
                .bottom{
                    span{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}